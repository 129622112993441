import React, {Component} from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';

require('./YoutubeVideoBG.scss')

let videoBG = require('./ColumbusSkyline.png');
let brandLogo = require('../../../../static/images/MoonlitAerial.png')


class YoutubeVideoBG extends Component {

    constructor(props) {
        super(props);
    }


    _onReady(event) {
        // access to player in all event handlers via event.target
        // event.target.mute();
    }

    _onEnd(event) {
        event.target.playVideo();
    }

    render() {
        const videoOptions = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                controls: 0,
                rel: 0,
                showinfo: 0,
            }
        };

        return (
            <div className="full-width-bg-wrapper">
                {/*<img src={videoBG} alt="placeholder 960" className="img-responsive"/>*/}

                        <div className="video-overlay">
                            <img src={brandLogo} alt=""/>
                                        <h1 className="site-banner-title">
                                            Professional Drone Services
                                            {/*{site.title}*/}
                                        </h1>
                                        <p className="site-banner-desc">
                                            Simple, Convenient, and completely custom aerial drone solutions
                                            {/*{site.description}*/}
                                        </p>
                                    </div>
                    </div>
        );
    }
}

YoutubeVideoBG.propTypes = {};

export default YoutubeVideoBG;
