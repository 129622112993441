import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import YoutubeVideoBG from './YoutubeVideoBG/YouTubeVideoBG'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'


import { Navigation } from '.'
import config from '../../utils/siteConfig'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Button} from "react-bootstrap"

// Styles
import '../../styles/app.css'
import ContactUsBtn from "./ContactUsBtn";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and tmeplate.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    let brandLogoCopy = require('../../../static/images/LogoCopyOnly.png')

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">

                <Navbar collapseOnSelect className="no-margin">
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a href="/">
                                <img src={brandLogoCopy} alt=""/>
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            <NavDropdown eventKey={3} title="Photography" id="basic-nav-dropdown">
                                <li eventKey={3.2}>
                                    <Link to='/residential-real-estate-marketing'>
                                        Residential & Commercial Real Estate
                                    </Link>
                                </li>
                            </NavDropdown>

                            <NavDropdown eventKey={3} title="Inspection" id="basic-nav-dropdown">

                                <li eventKey={3.2}>
                                    <Link to='/building-roof-inspection'>
                                        Roof Inspection
                                    </Link>
                                </li>
                                <li eventKey={3.2}>
                                    <Link to='/bridge-and-infrastructure'>
                                        Bridge & Infrastructure Inspection
                                    </Link>
                                </li>
                                <li eventKey={3.2}>
                                    <Link to='/power-solar-cell-tower-inspection'>
                                        Wind, Solar, & Cell Tower
                                    </Link>
                                </li>

                                <MenuItem eventKey={3.3}></MenuItem>
                            </NavDropdown>
                            <li eventKey={3.2}>
                                <Link to='/construction-project-management'>
                                    Construction
                                </Link>
                            </li>
                            <li eventKey={3.2}>
                                <Link to='/agriculture-turf'>
                                    Agriculture
                                </Link>
                            </li>
                            <NavItem eventKey={2} href="/about-us">
                                About
                            </NavItem>
                            <li eventKey={3.2}>
                                <ContactUsBtn/>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                { isHome ?
                    <div className="">
                        <YoutubeVideoBG/>
                    </div> :
                    null
                }

                {/*<Navigation data={site.navigation} navClass="site-nav-item" />*/}
                <div className="viewport-top">
                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">

                                { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
                                { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Twitter" /></a>}
                                <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="Twitter" /></a>
                                <Link to="/">{site.title}</Link> © 2019 &mdash; Published with <a className="site-foot-nav-item" href="https://ghost.org" target="_blank" rel="noopener noreferrer">Ghost</a>
                            </div>
                            <div className="site-foot-nav-right">
                                {/*{console.log('site', site);}*/}
                                <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
