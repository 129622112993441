import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Link } from "gatsby"
import { Button } from 'react-bootstrap';

class ContactUsBtn extends Component {
    render() {
        return (
            <Link to="/contact" {...this.props} style={{padding: '14px 10px 0px'}}>
                <Button className="site-nav-button" bsStyle="primary">Get a Quote</Button>
            </Link>
        );
    }
}

ContactUsBtn.propTypes = {};

export default ContactUsBtn;
